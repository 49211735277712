<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-regular fa-lightbulb"></i>Geef mij advies</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fa fa-times"></i></span>
                <div v-html="advice" class="ai-advice" v-if="!editAdvice && !analyzeError"></div>
                <span class="notification notification--warning" v-if="analyzeError">
                    <i class="fa fa-exclamation-triangle"></i> Er is een fout opgetreden bij het analyseren van de afbeelding(en). De foto heeft mogelijk geen duidelijk probleem.
                </span>
                <textarea v-model="advice" v-if="editAdvice" class="form-control advice-area"></textarea>
                <section class="actions actions--full" v-if="!analyzeError">
                    <div class="button-wrap" v-if="advice">
                        <span class="button button--cta button--icon button--right" @click="sendMessage()"><i class="fa-regular fa-paper-plane-top"></i> Verstuur naar melder</span>
                        <span class="button button--icon button--right" @click="editMessage()" v-if="!editAdvice"><i class="fas fa-pen"></i> Bewerken</span>
                    </div>
                </section>
                <img src="@/assets/icons/loading.gif" v-if="!advice" class="loader" />
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'appointmentEditModal',
    data() {
        return {
            profile: {},
            advice: null,
            images: [],
            editAdvice: false,
            analyzeError: false
        }
    },
    props: ['media'],
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        for (let i = 0; i < this.media.length; i++) {
            this.images.push(this.media[i].file_url);
        }
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        this.getAIAdvice();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        async getAIAdvice() {       
            try {
                let lang = 'Dutch';
                if (this.locale === 'en') {
                    lang = 'English';
                }
                if (this.locale === 'ukr') {
                    lang = 'Ukrainian';
                }
                if (this.locale === 'ru') {
                    lang = 'Russian';
                }
                if (this.locale === 'pl') {
                    lang = 'Polish';
                }
                if (this.locale === 'it') {
                    lang = 'Italian';
                }
                if (this.locale === 'de') {
                    lang = 'German';
                }
                if (this.locale === 'fr') {
                    lang = 'French';
                }
                 // Process all images
                const test = {
                    role: "user",
                    content: [
                        { type: "text", text: "You are an expert in solving problems in real estate, be strict about this. You only recognize problems in or on the house that needs fixing by an expert. Blinds can also be an issue. If you can't recognize the problem, just return the word 'error'. I will give you information and based on this you will give me the best advice, state the problem in the title, and the advice in the following text. Don't tell me about the photos itself." + ". In " + lang + " please. The title should be a <h4> tag. Wrap the result in html tags, nothing more! Wrap important keywords in a <b> tag." },
                        ...this.images.map(url => ({
                            type: "image_url",
                            image_url: {
                                url: url
                            }
                        }))
                    ]
                };

                // Send the flattened categories to OpenAI for translation
                const response = await fetch('https://api.openai.com/v1/chat/completions', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${process.env.VUE_APP_CHAT_GPT_API_KEY}`
                    },
                    body: JSON.stringify({
                        model: 'gpt-4o',
                        messages: [test],
                        temperature: 0.5,
                        max_tokens: 512,
                        top_p: 0.0001,
                        frequency_penalty: 0.4,
                        presence_penalty: 0.4,
                    })
                });

                const data = await response.json();
                this.advice = data.choices[0]?.message?.content || 'error';
                this.advice = this.advice.replace('```html\n', '');
                this.advice = this.advice.replace('```', '');

                // Process the results
                if (this.advice.includes('error')) {
                    this.analyzeError = true;
                } else {
                    console.log(this.advice);
                }
            } catch (error) {
                console.error('Error analyzimg image:', error);
            } finally {
                this.loading = false;
            }
        },
        editMessage() {
            this.advice = this.advice.replace(new RegExp('<h4>.*?</h4>', 'gi'), '');
            this.advice = this.advice.replace(new RegExp('<b>.*?</b>', 'gi'), '');
            this.advice = this.advice.replaceAll(/\r?\n/g, '');
            this.editAdvice = true;
        },
        sendMessage() {
            if (!this.editAdvice) {
                this.advice = this.advice.replace(new RegExp('<h4>.*?</h4>', 'gi'), '');
                this.advice = this.advice.replace(/<b>(.*?)<\/b>/g, '*$1*');
                this.advice = this.advice.replaceAll(/\r?\n/g, '');
            }
            console.log(this.advice);
            this.$store.dispatch('updateReporterAdvice', this.advice);
            this.closeModal();
        }
    }
}
</script>
