<template>
    <div>
        <div class="chat" :class="{active: chatOpen, enlarged: chatEnlarged}" v-if="showChat && dialogs && dialogs.length">
            <span class="chat-head" data-testid="incidentChatHead" @click="openChat()">
                <i class="fas fa-comments"></i> Chat
                <span class="newchats" v-if="newChats"></span>
            </span>
            <span class="enlarge-chat" @click="chatEnlarged = !chatEnlarged">
                <i class="fas fa-expand-alt"></i>
            </span>
            <div class="chat-body">
                <div class="dialogs" v-if="dialogs.length > 1">
                    <div class="dialog" v-for="(dialog, index) in dialogs" :key="index" @click="selectDialog(dialog)" v-show="!selectedDialog.nameShown">
                        <div class="row">
                            <div class="col">
                                <span class="unread-count" data-testid="incidentUnreadCounter" v-if="dialog.unread"></span>
                                <span class="profile-name" v-if="dialog.type === 'reporterChat'">
                                    <span class="chat-type head">{{profile.incident_detail.incident_reporter}} <span v-if="incidentInfo.reporter_phone === incidentInfo.owner_mobile" style="font-weight: 500;">& {{profile.incident_detail.incident_owner}}</span></span>
                                    <span class="name" data-testid="chatContactButton">
                                        <span class="initials">{{reporterInitials}}</span>
                                        {{incidentInfo.reporter_name}}
                                    </span>
                                </span>
                                <span class="profile-name" v-if="dialog.type === 'supplierChat'">
                                    <span class="chat-type head">{{profile.incidents.incidenttable.repair_company}}</span>
                                    <span class="name" data-testid="chatContactButton">
                                        <img :src="incidentInfo.repair_company_profile_image_loc" class="profile-pic" v-if="incidentInfo.repair_company_profile_image_loc && incidentInfo.repair_company_profile_image_loc !== '/images/no-profile-img.png'" />
                                        <span class="initials" v-else>{{repairCompanyInitials}}</span>
                                        {{incidentInfo.repair_company_name}}
                                    </span>
                                </span>
                                <span class="profile-name" v-if="dialog.type === 'specialistChat'">
                                    <span class="chat-type head">{{profile.incidents.incidenttable.mechanic}}</span>
                                    <span class="name" data-testid="chatContactButton">
                                        <img :src="incidentInfo.mechanic_profile_image_loc" class="profile-pic" v-if="incidentInfo.mechanic_profile_image_loc && incidentInfo.mechanic_profile_image_loc !== '/images/no-profile-img.png'" />
                                        <span class="initials" v-else>{{mechanicInitials}}</span>
                                        {{incidentInfo.mechanic_name}}
                                    </span>
                                </span>
                                <span class="profile-name" v-if="dialog.type === 'ownerChat' && incidentInfo.owner_mobile !== incidentInfo.reporter_phone">
                                    <span class="chat-type head" v-if="!incidentInfo.is_vve">{{profile.incident_detail.incident_owner}}</span>
                                    <span class="chat-type head" v-else>VvE</span>
                                    <span class="name" data-testid="chatContactButton">
                                        <img :src="incidentInfo.owner_profile_image_loc" class="profile-pic" v-if="incidentInfo.owner_profile_image_loc && incidentInfo.owner_profile_image_loc !== '/images/no-profile-img.png'" />
                                        <span class="initials" v-else>{{ownerInitials}}</span>
                                        {{incidentInfo.owner_name}}
                                    </span>
                                </span>
                                <span class="profile-name" v-if="dialog.type === 'ownerSeperateChat'" v-tooltip.left="dialog.description">
                                    <span class="chat-type head">{{profile.owner.portal}}</span>
                                    <span class="initials">{{ownerUserInitials}}</span>
                                    <span style="font-weight: 500;">{{dialog.nameShown}}</span>
                                </span>
                                <span class="profile-name" v-if="dialog.type === 'servicedeskChat'">
                                    <span class="chat-type head">{{profile.incident_detail.incident_building_manager}}</span>
                                    <span class="name" data-testid="chatContactButton">
                                        <span class="initials">{{servicedeskInitials}}</span>
                                        <span v-if="incidentInfo.original_id_building_manager !== buildingmanager.id_building_manager" style="font-weight: 500;">{{incidentInfo.original_building_manager_name}}</span>
                                        <span v-else style="font-weight: 500;">{{incidentInfo.building_manager_name}}</span>
                                    </span>
                                </span>
                                <div class="message-row" v-if="dialog.messages && dialog.messages.length">
                                    <i class="fas fa-comments"></i>
                                    <p class="message" v-if="!dialog.lastMessage.body.includes('digitalocean') && !dialog.lastMessage.body.includes('twilio') || !dialog.lastMessage.mediaContentType" v-html="dialog.lastMessage.body"></p>
                                    <p class="message" v-if="dialog.lastMessage.mediaContentType && (dialog.lastMessage.body.includes('digitalocean') || dialog.lastMessage.body.includes('twilio')) && (dialog.lastMessage.mediaContentType.includes('image/') || dialog.lastMessage.mediaContentType.includes('video/'))">
                                        <span v-if="dialog.lastMessage.sent">{{incidentInfo.building_manager_name}}: </span>
                                        <span v-if="dialog.type === 'reporterChat' && !dialog.lastMessage.sent">{{incidentInfo.reporter_name}}: </span>
                                        <span v-if="dialog.type === 'supplierChat' && !dialog.lastMessage.sent">{{incidentInfo.repair_company_name}}: </span>
                                        <span v-if="dialog.type === 'ownerChat' && !dialog.lastMessage.sent">{{incidentInfo.owner_name}}: </span>
                                        <span v-if="dialog.type === 'specialistChat' && !dialog.lastMessage.sent">{{incidentInfo.mechanic_name}}: </span>
                                    </p>
                                    <img :src="dialog.lastMessage.body" class="message-image" v-if="dialog.lastMessage.mediaContentType && (dialog.lastMessage.body.includes('digitalocean') || dialog.lastMessage.body.includes('twilio')) && dialog.lastMessage.mediaContentType.includes('image/')" style="margin-bottom: 3px;" />
                                    <video v-if="dialog.lastMessage.mediaContentType && (dialog.lastMessage.body.includes('digitalocean') || dialog.lastMessage.body.includes('twilio')) && dialog.lastMessage.mediaContentType.includes('video/')" muted autoplay loop><source :src="dialog.lastMessage.body" type="video/mp4"></video>
                                </div>
                                <span class="timedate" v-if="dialog.lastMessage">{{dialog.lastMessage.time}}</span>
                            </div>
                        </div>
                        <div class="setasread" v-if="dialog.receivedChats" v-tooltip.right="profile.incidents.incidenttable.set_chat_as_unread" @click.stop="setAsUnread(dialog)"><img src="@/assets/icons/unread-message.svg" /></div>
                    </div>
                </div>
                <div class="selected-dialog" v-if="selectedDialog.nameShown" :class="{single: singleDialog}">
                    <div class="dialog-head">
                        <span class="back-link" @click="backButton()" v-if="!singleDialog"><i class="fas fa-arrow-left"></i></span>
                        <span class="profile-name" v-if="selectedDialog.type === 'reporterChat'">
                            <span class="chat-type head">{{profile.incident_detail.incident_reporter}} <span v-if="incidentInfo.reporter_phone === incidentInfo.owner_mobile" style="font-weight: 500;">& {{profile.incident_detail.incident_owner}}</span></span>
                            <span class="name" data-testid="chatContactButton">
                                <span class="initials">{{reporterInitials}}</span>
                                <p>{{incidentInfo.reporter_name}}</p>
                            </span>
                            <div class="setasread" style="float: right; margin-right: 5px; cursor: pointer;" v-if="selectedDialog.receivedChats" v-tooltip.right="profile.incidents.incidenttable.set_chat_as_unread" @click.stop="setAsUnread(selectedDialog)"><img src="@/assets/icons/unread-message.svg" style="margin: 0;" /></div>
                        </span>
                        <span class="profile-name" v-if="selectedDialog.type === 'supplierChat'">
                            <span class="chat-type head">{{profile.incidents.incidenttable.repair_company}}</span>
                            <span class="name" data-testid="chatContactButton">
                                <img :src="incidentInfo.repair_company_profile_image_loc" class="profile-pic" v-if="incidentInfo.repair_company_profile_image_loc && incidentInfo.repair_company_profile_image_loc !== '/images/no-profile-img.png'" />
                                <span class="initials" v-else>{{repairCompanyInitials}}</span>
                                <p>{{incidentInfo.repair_company_name}}</p>
                            </span>
                        </span>
                        <span class="profile-name" v-if="selectedDialog.type === 'specialistChat'">
                            <span class="chat-type head">{{profile.incidents.incidenttable.mechanic}}</span>
                            <span class="name" data-testid="chatContactButton">
                                <img :src="incidentInfo.mechanic_profile_image_loc" class="profile-pic" v-if="incidentInfo.mechanic_profile_image_loc && incidentInfo.mechanic_profile_image_loc !== '/images/no-profile-img.png'" />
                                <span class="initials" v-else>{{mechanicInitials}}</span>
                                <p>{{incidentInfo.mechanic_name}}</p>
                            </span>
                        </span>
                        <span class="profile-name" v-if="selectedDialog.type === 'ownerChat'">
                            <span class="chat-type head">{{profile.incident_detail.incident_owner}}</span>
                            <span class="name" data-testid="chatContactButton">
                                <img :src="incidentInfo.owner_profile_image_loc" class="profile-pic" v-if="incidentInfo.owner_profile_image_loc && incidentInfo.owner_profile_image_loc !== '/images/no-profile-img.png'" />
                                <span class="initials" v-else>{{ownerInitials}}</span>
                                <p>{{incidentInfo.owner_name}}</p>
                            </span>
                        </span>
                        <span class="profile-name" v-if="selectedDialog.type === 'ownerSeperateChat'" v-tooltip.top="selectedDialog.description">
                            <span class="chat-type head">{{profile.owner.portal}}</span>
                            <span class="name">
                                <span class="initials">{{ownerUserInitials}}</span>
                                <p>{{selectedDialog.nameShown}}</p>
                            </span>
                        </span>
                        <span class="profile-name" v-if="selectedDialog.type === 'servicedeskChat'">
                            <span class="chat-type head">{{profile.incident_detail.incident_building_manager}}</span>
                            <span class="name" data-testid="chatContactButton">
                                <span class="initials">{{servicedeskInitials}}</span>
                                <span style="font-weight: 500;" v-if="incidentInfo.original_id_building_manager !== buildingmanager.id_building_manager">{{incidentInfo.original_building_manager_name}}</span>
                                <span style="font-weight: 500;" v-else>{{incidentInfo.building_manager_name}}</span>
                            </span>
                        </span>
                    </div>
                    <div class="messages" id="messages" :class="{readonly: readOnly}">
                        <div class="message" v-for="(message, index) in selectedDialog.messages" :key="index" :class="{received: message.received, sent: message.sent, undelivered: message.status === 'whatsapp_undelivered', mediaText: message.mediaText !== '' && message.mediaText !== ' ' && message.mediaText !== 'null ', translated: incidentInfo.reporter_locale !== 'nl-NL' && buildingmanager.auto_translate && message.body !== message.bodyOriginal}" v-show="message.body">
                            <span class="lang" v-if="incidentInfo.reporter_locale !== 'nl-NL' && message.received && buildingmanager.auto_translate && message.body !== message.bodyOriginal" v-tooltip.right="message.bodyOriginal">
                                <img src="@/assets/icons/flags/en.svg" v-if="incidentInfo.reporter_locale === 'en-GB'">
                                <img src="@/assets/icons/flags/it.svg" v-if="incidentInfo.reporter_locale === 'it-IT'">
                                <img src="@/assets/icons/flags/pl.svg" v-if="incidentInfo.reporter_locale === 'pl-PL'">
                                <img src="@/assets/icons/flags/ukr.svg" v-if="incidentInfo.reporter_locale === 'uk-UA'">
                                <img src="@/assets/icons/flags/ru.svg" v-if="incidentInfo.reporter_locale === 'ru-RU'">
                                <img src="@/assets/icons/flags/de.svg" v-if="incidentInfo.reporter_locale === 'de-DE'">
                                <img src="@/assets/icons/flags/fr.svg" v-if="incidentInfo.reporter_locale === 'fr-FR'">
                            </span>
                            <span class="lang" v-if="incidentInfo.reporter_locale !== 'nl-NL' && message.sent && buildingmanager.auto_translate && message.body !== message.bodyOriginal" v-tooltip.right="message.body">
                                <img src="@/assets/icons/flags/en.svg" v-if="incidentInfo.reporter_locale === 'en-GB'">
                                <img src="@/assets/icons/flags/it.svg" v-if="incidentInfo.reporter_locale === 'it-IT'">
                                <img src="@/assets/icons/flags/pl.svg" v-if="incidentInfo.reporter_locale === 'pl-PL'">
                                <img src="@/assets/icons/flags/ukr.svg" v-if="incidentInfo.reporter_locale === 'uk-UA'">
                                <img src="@/assets/icons/flags/ru.svg" v-if="incidentInfo.reporter_locale === 'ru-RU'">
                                <img src="@/assets/icons/flags/de.svg" v-if="incidentInfo.reporter_locale === 'de-DE'">
                                <img src="@/assets/icons/flags/fr.svg" v-if="incidentInfo.reporter_locale === 'fr-FR'">
                            </span>
                            <img :src="message.body" class="image" data-testid="chatVideoMessage" v-if="message.mediaContentType && message.mediaContentType.includes('image/')" />
                            <video data-testid="chatVideoMessage" v-if="message.mediaContentType && message.mediaContentType.includes('video')" muted loop autoplay><source :src="message.body" type="video/mp4"></video>
                            <p data-testid="chatPdfMessage" v-if="message.isMedia && message.body.includes('.pdf')"><a :href="message.body" style="color: white; text-decoration: none;" target="blank"><i class="fas fa-file-pdf" style="font-size: 24px; margin-right: 5px;"></i> {{message.docName}}</a></p>
                            <p data-testid="chatTextMessage" v-if="message.mediaText !== '' && message.mediaText !== ' ' && message.mediaText !== undefined && !message.mediaText.includes('null')" class="media-text">{{message.mediaText}}</p>
                            <p data-testid="chatMediaMessage" v-if="!message.isMedia && !message.sent" v-html="message.body"></p>
                            <p data-testid="chatMediaMessage" v-if="!message.isMedia && message.sent && buildingmanager.auto_translate" v-html="message.bodyOriginal"></p>
                            <p data-testid="chatMediaMessage" v-if="!message.isMedia && message.sent && !buildingmanager.auto_translate" v-html="message.body"></p>
                            <span data-testid="chatMessageTime"><b v-if="message.status === 'whatsapp_undelivered'">{{profile.incident_detail.whatsapp_undelivered}} | </b>{{message.time}}</span>
                        </div>
                    </div>
                    <form v-on:submit.prevent="sendMessage()" class="new-message" v-if="(!incidentReadOnly || forwardedReadOnly) || !forwardedReadOnly" v-show="!incidentReadOnly || (incidentInfo.incident_agreement_flow === 'WAITING_ON_PERSON' || incidentInfo.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED')">
                        <div style="float: left; width: 100%; position: relative;">
                            <span class="add-attachment" data-testid="chatFileButton" @click="mediaModalOpen = true" v-tooltip.right="profile.accountPage.account.add_files"><i class="fas fa-paperclip"></i></span>
                            <span class="add-defaultmessage" data-testid="chatDefaultButton" @click="defaultMessageModalOpen = true" v-tooltip.right="profile.accountPage.account.add_default_message"><i class="far fa-comment-dots"></i></span>
                            <textarea class="form-control" data-testid="chatTextInput" rows="2" placeholder="Verstuur een bericht..." @input="e => newMessage = e.target.value" :value="newMessage" @click="scrollToBottom()" @keydown="sendMessage($event)" id="textinput" maxlength="1500" ref="messageInput"></textarea>
                            <button type="submit" data-testid="chatSendButton" v-if="((!incidentReadOnly || forwardedReadOnly) || !forwardedReadOnly) && !typing"><i class="fas fa-paper-plane"></i></button>
                            <div class="typing" v-if="typing"><img src="@/assets/icons/loading.gif" /></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <MediaModal v-if="mediaModalOpen" @close="getIncidentInfo()" @success="showWhatsAppChats()" @sendMessage="sendMessage" :incidentId="incidentInfo.id_incident" :files="incidentInfo.pictures" :selectedDialog="selectedDialog" />
        <DefaultChatMessageModal v-if="defaultMessageModalOpen" @close="defaultMessageModalOpen = false" @messageSelected="fillDefaultChat" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { setLocale } from '@/mixins/setLocale.js';
import { websocket } from '@/mixins/websocket.js';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import MediaModal from "@/components/modals/MediaModal.vue";
import DefaultChatMessageModal from "@/components/modals/DefaultChatMessageModal.vue";

export default {
    mixins: [setLocale, websocket],
    components: {
        MediaModal,
        DefaultChatMessageModal
    },
    name: 'Chat',
    props: ['buildingmanager', 'incidentId', 'incidentInfo', 'incidentReadOnly', 'forwardedReadOnly', 'canOwnerChat'],
    data() {
        return {
            profile: {},
            userName: '',
            userNames: [],
            dialogs: [],
            selectedDialog: {},
            singleDialog: false,
            showChat: false,
            chatOpen: false,
            newChats: false,
            newMessage: '',
            chatEnlarged: false,
            readOnly: false,
            mediaModalOpen: false,
            reporterMobile: '',
            repairCompanyInitials: '',
            reporterInitials: '',
            mechanicInitials: '',
            ownerInitials: '',
            servicedeskInitials: '',
            defaultMessageModalOpen: false,
            typing: true,
            ownerUserInitials: '',
            ownerUserId: ''
        }
    },
    computed: mapState(['fileRemoved', 'quotationAdded', 'colleagueAssigned', 'ownerChat', 'ownerChatId', 'reloadChats', 'reporterAdvice']),
    watch: {
        fileRemoved() {
            let message = '';
            if (this.locale === 'nl') {
                message = 'Er is een beeld / bestand uit de melding verwijderd.'
            }
            else {
                message = 'A file / image has been removed from the incident.'
            }
            this.sendMessage(message);
        },
        quotationAdded() {
            let message = '';
            if (this.locale === 'nl') {
                message = 'Er is een offerte toegevoegd aan de melding.'
            }
            else {
                message = 'A quotation has been removed from the incident.'
            }
            this.sendMessage(message);
        },
        colleagueAssigned() {
            if (this.colleagueAssigned) {
                let message = '';
                if (this.locale === 'nl') {
                    message = 'Offerte wordt beoordeeld door ' + this.buildingmanager.building_manager_name;
                }
                else {
                    message = 'Offer is reviewed by ' + this.buildingmanager.building_manager_name;
                }
                this.sendMessage(message, true);
            }
        },
        reporterAdvice() {
            if (this.reporterAdvice) {
                this.sendMessage(this.reporterAdvice, true);
            }
        },  
        appointmentMessage() {
            if (this.appointmentMessage) {
                this.sendMessage(this.appointmentMessage, true);
            } 
        },
        ownerChat() {
            setTimeout(function () {
                this.sendMessage(this.ownerChat, false, true);
            }.bind(this), 200);  
            setTimeout(function () {
                this.$emit('getIncidentInfo');
                this.selectedDialog = {};
                this.singleDialog = false;
                this.showChat = true;
                this.addWhatsappToChat();
            }.bind(this), 1000);  
        },  
        ownerChatId() {
            this.ownerUserId = this.ownerChatId;
        },  
        reloadChats() {
            this.$emit('getIncidentInfo');
            setTimeout(function () {
                this.selectedDialog = {};
                this.singleDialog = false;
                this.showChat = true;
                this.addWhatsappToChat();
            }.bind(this), 500);  
        }
    },
    created() {
        this.getCurrentUser();
        this.addWhatsappToChat();
        this.$root.$on('newchat', () => {
            this.showWhatsAppChats();
        });
    },
    methods: {
        getIncidentInfo() {
            this.$emit('getIncidentInfo');
            this.showWhatsAppChats();
            this.mediaModalOpen = false;
        },
        getCurrentUser() {
            let self = this;
            fetch('/api/v1/getcurrentuser')
	        .then(response => { response.json().then(
	            function(data) {
					self.userName = data.name;
				});
	        });
        },
        backButton() {
            this.selectedDialog = {};
        },
        openChat() {
            this.chatOpen = !this.chatOpen;
            if (this.dialogs.length === 1 && this.chatOpen) {
                this.newChats = false;
                if (this.selectedDialog.type === 'reporterChat') {
                    this.setwhatsappasread('reporter');
                }
                if (this.selectedDialog.type === 'ownerChat') {
                    this.setwhatsappasread('owner');
                }
                if (this.selectedDialog.type === 'ownerSeperateChat') {
                    this.setwhatsappasread('owneruser');
                }
                if (this.selectedDialog.type === 'specialistChat') {
                    this.setwhatsappasread('mechanic');
                }
                if (this.selectedDialog.type === 'servicedeskChat') {
                    this.setwhatsappasread('servicedesk');
                }
                if (this.selectedDialog.type === 'supplierChat') {
                    this.setwhatsappasread('supplier');
                }
                setTimeout(function () {
                    if (!this.readOnly) {
                        document.getElementById('textinput').focus();
                    }
                    let element = document.getElementById('messages');
                    if (element) {
                        element.scrollTop = element.scrollHeight;
                    }
                }.bind(this), 200);
            }
        },
        scrollToBottom() {
            let element = document.getElementById('messages');
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
        },
        addWhatsappToChat() {
            if (this.incidentInfo.reporter_name.includes(" ")) {
                this.reporterInitials = this.incidentInfo.reporter_name.split(" ").map((n)=>n[0]).join("");
            }
            if (this.incidentInfo.repair_company_name) {
                this.repairCompanyInitials = this.incidentInfo.repair_company_name.split(" ").map((n)=>n[0]).join("");
            }
            if (this.incidentInfo.mechanic_name) {
                this.mechanicInitials = this.incidentInfo.mechanic_name.split(" ").map((n)=>n[0]).join("");
            }
            if (this.incidentInfo.owner_name) {
                this.ownerInitials = this.incidentInfo.owner_name.split(" ").map((n)=>n[0]).join("");
            }
            if (this.incidentInfo.original_id_building_manager && this.incidentInfo.original_id_building_manager !== this.buildingmanager.id_building_manager) {
                this.servicedeskInitials = this.incidentInfo.original_building_manager_name.split(" ").map((n)=>n[0]).join("");
            }
            else {
                this.servicedeskInitials = this.buildingmanager.building_manager_name.split(" ").map((n)=>n[0]).join("");
            }
            this.showChat = false;
            this.dialogs = [];
            if (!this.dialogs.length) {
                let self = this;
                // Add supplier chat to the list
                if (this.incidentInfo.id_repair_company && this.incidentInfo.id_repair_company !== this.incidentInfo.canbe_id_repair_company) {
                    fetch('/api/v1/chat/unreadincident?id_incident='+this.incidentInfo.id_incident + '&type=servicedesk_supplier', {
                    }).then((resp) => {
                        return resp.json();
                    }).then(function(data) {
                        let mechanicDialog = {
                            nameShown: self.incidentInfo.repair_company_name,
                            unread: data.count,
                            type: 'supplierChat',
                            messages: []
                        }
                        if (data.count) {
                            self.newChats = true;
                        }
                        self.dialogs.push(mechanicDialog);
                    });
                }
                // Add mechanic chat to the list
                if (this.incidentInfo.id_repair_company === this.incidentInfo.canbe_id_repair_company && this.incidentInfo.id_mechanic && !this.incidentInfo.mechanic_only_email && !this.forwardedReadOnly) {
                    fetch('/api/v1/chat/unreadincident?id_incident='+this.incidentInfo.id_incident + '&type=mechanic', {
                    }).then((resp) => {
                        return resp.json();
                    }).then(function(data) {
                        let mechanicDialog = {
                            nameShown: self.incidentInfo.mechanic_name,
                            unread: data.count,
                            type: 'specialistChat',
                            messages: []
                        }
                        if (data.count) {
                            self.newChats = true;
                        }
                        self.dialogs.push(mechanicDialog);
                    });
                }
                // Add owner chat to the list
                if (this.incidentInfo.id_building_manager === this.incidentInfo.original_id_building_manager && this.canOwnerChat) {
                    if (this.incidentInfo.owner_mobile && this.incidentInfo.reporter_phone !== this.incidentInfo.owner_mobile && !this.forwardedReadOnly) {
                        fetch('/api/v1/chat/unreadincident?id_incident='+this.incidentInfo.id_incident + '&type=owner', {
                        }).then((resp) => {
                            return resp.json();
                        }).then(function(data) {
                            let ownerDialog = {
                                nameShown: self.incidentInfo.owner_name,
                                unread: data.count,
                                type: 'ownerChat',
                                messages: []
                            }
                            if (data.count) {
                                self.newChats = true;
                            }
                            self.dialogs.push(ownerDialog);
                        });
                    }
                }
                // Add seperate owner chat to the list
                if (this.incidentInfo.ownerUserChats.length) {
                    fetch('/api/v1/chat/unreadincident?id_incident='+this.incidentInfo.id_incident + '&type=servicedesk_owneruser', {
                    }).then((resp) => {
                        return resp.json();
                    }).then(function(data) {
                        let totalCount = data.count;
                        if (totalCount) {
                            self.newChats = true;
                        }
                        fetch('/api/v1/owneraccount/getownerusers?id_owner=' + self.incidentInfo.id_owner)
                        .then(response => { response.json().then(
                            function(data) {
                                let ownerSeperateDialog = {
                                    nameShown: '',
                                    unread: totalCount,
                                    type: 'ownerSeperateChat',
                                    messages: []
                                }
                                self.ownerUsers = data;
                                self.ownerUserId = self.incidentInfo.ownerUserChats[0].receiver.split('-')[1];
                                for (var i = 0; i < self.ownerUsers.length; i++) {
                                    let ownerUser = self.ownerUsers[i];
                                    if (ownerUser.id_owner_shiro_user.toString() === self.ownerUserId) {
                                        ownerSeperateDialog.nameShown = ownerUser.actual_name;
                                        ownerSeperateDialog.description = ownerUser.role_description;
                                    }
                                }
                                self.ownerUserInitials = ownerSeperateDialog.nameShown.split(" ").map((n)=>n[0]).join("");
                                self.dialogs.push(ownerSeperateDialog);
                            });
                        }); 
                    });
                }
                // Add reporter chat to the list
                if (this.incidentInfo.whatsapp && !this.forwardedReadOnly) {
                    fetch('/api/v1/chat/unreadincident?id_incident='+this.incidentInfo.id_incident + '&type=reporter', {
                    }).then((resp) => {
                        return resp.json();
                    }).then(function(data) {
                        let reporterDialog = {
                            nameShown: self.incidentInfo.reporter_name,
                            unread: data.count,
                            reporterChat: true,
                            type: 'reporterChat',
                            messages: []
                        }
                        if (data.count) {
                            self.newChats = true;
                        }
                        self.dialogs.push(reporterDialog);
                    });
                }
                // Add servicedesk chat to the list
                if (this.incidentInfo.original_id_building_manager && this.incidentInfo.original_id_building_manager !== this.incidentInfo.id_building_manager) {
                    fetch('/api/v1/chat/unreadincident?id_incident='+this.incidentInfo.id_incident + '&type=servicedesk', {
                    }).then((resp) => {
                        return resp.json();
                    }).then(function(data) {
                        let servicedeskDialog = {
                            nameShown: self.incidentInfo.original_building_manager_name,
                            unread: data.count,
                            type: 'servicedeskChat',
                            messages: []
                        }
                        if (data.count) {
                            self.newChats = true;
                        }
                        self.dialogs.push(servicedeskDialog);
                    });
                }
                setTimeout(function () {
                    this.showWhatsAppChats();
                    let queryString = window.location.search;
                    let openchat;
                    if (queryString.includes('openchat')) {
                        openchat = queryString.split('=')[2];
                    }
                    if (openchat) {
                        this.chatOpen = true;
                    }
                    // If there is only 1 dialogue, set the first / only one to the selected dialogue
                    if (this.dialogs.length === 1) {
                        this.selectedDialog = this.dialogs[0];
                        this.singleDialog = true;
                    }
                    else {
                        this.selectedDialog = {};
                        this.singleDialog = false;
                        for (var i = 0; i < this.dialogs.length; i++) {
                            let dialog = this.dialogs[i];
                            if (openchat === dialog.type) {
                                this.selectedDialog = dialog;
                            }
                        }
                        this.dialogs = sortBy(this.dialogs, 'reporterChat');
                    }
                    this.showChat = true;
                    if (this.chatOpen) {
                        if (this.selectedDialog.type === 'reporterChat') {
                            this.setwhatsappasread('reporter');
                        }
                        if (this.selectedDialog.type === 'ownerChat') {
                            this.setwhatsappasread('owner');
                        }
                        if (this.selectedDialog.type === 'ownerSeperateChat') {
                            this.setwhatsappasread('owneruser');
                        }
                        if (this.selectedDialog.type === 'specialistChat') {
                            this.setwhatsappasread('mechanic');
                        }
                        if (this.selectedDialog.type === 'servicedeskChat') {
                            this.setwhatsappasread('servicedesk');
                        }
                        if (this.selectedDialog.type === 'supplierChat') {
                            this.setwhatsappasread('supplier');
                        }
                        this.newChats = false;
                    }
                }.bind(this), 2000);
            }
        },
        selectDialog(dialog) {
            this.selectedDialog = dialog;
            if (dialog.type === 'reporterChat') {
                this.setwhatsappasread('reporter');
                dialog.unread = false;
            }
            if (dialog.type === 'ownerChat') {
                this.setwhatsappasread('owner');
                dialog.unread = false;
            }
            if (dialog.type === 'ownerSeperateChat') {
                this.setwhatsappasread('owneruser');
                dialog.unread = false;
            }
            if (dialog.type === 'specialistChat') {
                this.setwhatsappasread('mechanic');
                dialog.unread = false;
            }
            if (dialog.type === 'servicedeskChat') {
                this.setwhatsappasread('servicedesk');
                dialog.unread = false;
            }
            if (dialog.type === 'supplierChat') {
                this.setwhatsappasread('supplier');
                dialog.unread = false;
            }
            setTimeout(function () {
                let element = document.getElementById('messages');
                if (element) {
                    element.scrollTop = element.scrollHeight;
                }
            }.bind(this), 500);
        },
        showWhatsAppChats(clearSelectedDialog) {
            this.mediaModalOpen = false;
            if (clearSelectedDialog) {
                this.selectedDialog = {};
            }
            for (var i = 0; i < this.dialogs.length; i++) {
                let dialog = this.dialogs[i];
                // Reporter chats
                if (dialog.type === 'reporterChat') {
                    let self = this;
                    fetch('/api/v1/chat/byincident?id_incident='+this.incidentInfo.id_incident+'&limit='+100 + '&type=reporter', {
                    }).then((resp) => {
                        return resp.json();
                    }).then(function(data) {
                        self.typing = false;
                        self.processChats(dialog, data);
                    });
                }
                // Owner chats
                if (dialog.type === 'ownerChat' && this.incidentInfo.reporter_phone !== this.incidentInfo.owner_mobile) {
                    let self = this;
                    fetch('/api/v1/chat/byincident?id_incident='+this.incidentInfo.id_incident+'&limit='+100 + '&type=owner', {
                    }).then((resp) => {
                        return resp.json();
                    }).then(function(data) {
                        self.typing = false;
                        self.processChats(dialog, data);
                    });
                }
                // Owner seperate chats
                if (dialog.type === 'ownerSeperateChat') {
                    let self = this;
                    fetch('/api/v1/chat/byincident?id_incident='+this.incidentInfo.id_incident+'&limit='+100 + '&type=servicedesk_owneruser', {
                    }).then((resp) => {
                        return resp.json();
                    }).then(function(data) {
                        self.typing = false;
                        self.processChats(dialog, data);
                        if (self.ownerChat) {
                            self.setwhatsappasread('owneruser');
                        }
                    });
                }
                // Supplier chats
                if (dialog.type === 'supplierChat') {
                    let self = this;
                    fetch('/api/v1/chat/byincident?id_incident='+this.incidentInfo.id_incident+'&limit='+100 + '&type=servicedesk_supplier', {
                    }).then((resp) => {
                        return resp.json();
                    }).then(function(data) {
                        self.typing = false;
                        self.processChats(dialog, data);
                    });
                }
                // Specialist chats
                if (dialog.type === 'specialistChat') {
                    let self = this;
                    fetch('/api/v1/chat/byincident?id_incident='+this.incidentInfo.id_incident+'&limit='+100 + '&type=mechanic', {
                    }).then((resp) => {
                        return resp.json();
                    }).then(function(data) {
                        self.typing = false;
                        self.processChats(dialog, data);
                    });
                }
                // Servicedesk chats
                if (dialog.type === 'servicedeskChat') {
                    let self = this;
                    fetch('/api/v1/chat/byincident?id_incident='+this.incidentInfo.id_incident+'&limit='+100 + '&type=servicedesk', {
                    }).then((resp) => {
                        return resp.json();
                    }).then(function(data) {
                        self.typing = false;
                        self.processChats(dialog, data);
                    });
                }
            }
            setTimeout(function () {
                for (var i = 0; i < this.dialogs.length; i++) {
                    let dialog = this.dialogs[i];
                    if (dialog.type === 'supplierChat' && (this.incidentInfo.repair_company_only_email || this.incidentInfo.repair_company_no_communication) && dialog.messages.length === 0) {
                        this.dialogs.splice(i, 1);
                    }
                }
                if (this.dialogs.length === 1) {
                    this.selectedDialog = this.dialogs[0];
                    this.singleDialog = true;
                }
            }.bind(this), 500);
        },
        processChats(dialog, data) {
            dialog.unread = data.unread_count;
            if (dialog.unread) {
                this.newChats = true;
            }
            for (var i = 0; i < data.messages.length; i++) {
                let dateStr = '';
                let received = false;
                let sent = false;
                let mediaText = '';
                if (data.messages[i].dateSentMillis) {
                    let dateSent = new Date(data.messages[i].dateSentMillis);
                    let month = dateSent.getMonth() + 1;
                    month = month.toString();
                    dateStr = dateSent.getDate() + " " + this.profile.months_numbered[month]  + ", " + dateSent.getHours() + ":"+this.pad(dateSent.getMinutes());
                }
                if (data.messages[i].from.indexOf("+3197")==-1) {
                    received = true;
                }
                else {
                    sent = true;
                }
                if (data.messages[i].from.includes('rc-') || data.messages[i].from.includes('mc-')) {
                    received = true;
                    sent = false;
                }
                if (data.messages[i].from.includes('bm-')) {
                    let sentBm = data.messages[i].from.split('bm-')[1];
                    if (sentBm === this.incidentInfo.id_building_manager.toString()) {
                        sent = true;
                        received = false;
                    }
                   else {
                        sent = false;
                        received = true;
                    }
                }
                if (data.messages[i].isMedia) {
                    mediaText = data.messages[i].body.split('https')[0];
                    data.messages[i].body = 'https' + data.messages[i].body.split('https')[1];
                }
                let message = {
                    body: data.messages[i].body,
                    bodyOriginal: data.messages[i].bodyOriginal,
                    from: data.messages[i].from,
                    time: dateStr,
                    received: received,
                    sent: sent,
                    isMedia: data.messages[i].isMedia,
                    mediaText: mediaText,
                    docName: '',
                    status: data.messages[i].status,
                    timestamp: data.messages[i].dateSentMillis,
                    isBMNotification: data.messages[i].isBMNotification,
                    mediaContentType: data.messages[i].mediaContentType,
                    idChat: data.messages[i].idChat
                }
                if (data.messages[i].isMedia && !data.messages[i].mediaContentType) {
                    message.mediaContentType = 'image/jpeg';
                }
                else {
                    message.body = message.body.replaceAll(/\r?\n/g, '<br>');
                    message.body = message.body.replace(/\*(.*?)\*/g, '<b>$1</b>');
                }
                if (message.bodyOriginal) {
                    message.bodyOriginal = message.bodyOriginal.replaceAll(/\r?\n/g, '<br>');
                    message.bodyOriginal = message.bodyOriginal.replace(/\*(.*?)\*/g, '<b>$1</b>');
                }
                if (message.body.includes('.pdf')) {
                    let n = message.body.lastIndexOf('/');
                    let fileName = message.body.substring(n + 1);
                    message.docName = fileName;
                }
                if (message.received) {
                    dialog.receivedChats = true;
                }
                if (dialog.nameShown) {
                    if (!message.isBMNotification && message.status !== 'resend') {
                        dialog.messages.push(message);
                    }
                    dialog.messages = uniqBy(dialog.messages, 'timestamp');
                    dialog.messages = sortBy(dialog.messages, 'timestamp');
                    dialog.lastMessage = dialog.messages.slice(-1).pop();
                    if (this.selectedDialog.messages && this.selectedDialog.messages.length) {
                        setTimeout(function () {
                            let element = document.getElementById('messages');
                            if (element) {
                                element.scrollTop = element.scrollHeight;
                            }
                            if (this.userName === this.incidentInfo.serviced_by_bm_user_first_name) {
                                dialog.unread = 0;
                            }
                        }.bind(this), 200);
                    }
                }
            }
        },
        setwhatsappasread(type) {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('type', type);
            let self = this;
            fetch('/api/v1/chat/setwhatsappasread', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                setTimeout(function() {
                    if (self.dialogs.length > 1) {
                        const unreadCheck = self.dialogs.every(({ unread }) => !unread);
                        if (unreadCheck) {
                            self.newChats = false;
                        }
                        else {
                            self.newChats = true;
                        }
                    }
                    if (self.dialogs.length < 1 || self.selectedDialog.type) {
                        self.newChats = false;
                    }
                    if (type === 'owneruser') {
                        this.newChats = false;
                    }
                    self.$store.dispatch('updateChatRead');
                }.bind(self), 500);
            });
        },
        pad(n) {
            return n < 10 ? '0' + n : n
        },
        createNiceDateString(theDate) {
            var result = '';
            let now = new Date();
            let startToday = now.getTime()-(now.getMilliseconds()+now.getSeconds()*1000+now.getMinutes()*60*1000+now.getHours()*60*60*1000);
            let startTheDay = theDate.getTime()-(theDate.getMilliseconds()+theDate.getSeconds()*1000+theDate.getMinutes()*60*1000+theDate.getHours()*60*60*1000);
            let hoursMinutes = this.pad(theDate.getHours())+':'+this.pad(theDate.getMinutes());
            if (startTheDay==startToday) {
                result += hoursMinutes;
            } else if (startTheDay+24*60*60*1000==startToday) {
                let yesterdayStr = this.profile.datetime.yesterday;
                result +=  hoursMinutes+' '+yesterdayStr;
            } else {
                result = this.pad(theDate.getDate())+"/"+this.pad(theDate.getMonth()+1)+"/"+theDate.getFullYear();
            }
            return result;
        },
        sendMessage($event, reporterChat, ownerChat) {
            let queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const id = urlParams.get('id');
            let textarea = this.$refs.messageInput;
            if (this.incidentInfo.id_incident.toString() === id) {
                document.querySelector('body').classList.remove('modal-open');
                this.mediaModalOpen = false;
                if ($event) {
                    if (typeof $event === 'string') {
                        this.getIncidentInfo();
                    }
                    if ($event.keyCode === 13 && !this.newMessage.length) {           
                        textarea.blur();
                        setTimeout(function () {
                            textarea.focus();
                        }.bind(this), 200);                
                    }
                    if (!ownerChat && ((!this.incidentReadOnly || this.forwardedReadOnly) || !this.forwardedReadOnly)) {
                        if(($event.keyCode === 13 && this.newMessage.length || typeof $event === 'string') && !$event.shiftKey) {
                            this.handleSend($event, reporterChat);
                        }
                    }
                    if (ownerChat && ((!this.incidentReadOnly || this.forwardedReadOnly) || !this.forwardedReadOnly)) {
                        if(($event.keyCode === 13 && this.newMessage.length || typeof $event === 'string') && !$event.shiftKey) {
                            this.handleSend($event, false);                      
                            setTimeout(function() {
                                this.setwhatsappasread('owneruser');
                            }.bind(this), 2000);
                        }
                    }
                } 
                else {
                    if (!ownerChat && (!this.incidentReadOnly || this.forwardedReadOnly) || !this.forwardedReadOnly) {
                        if(this.newMessage.length) {
                            this.handleSend($event, reporterChat);
                        }
                    }
                }   
            }
            this.$store.dispatch('updateQuotationAdded', false);
        },
        handleSend($event, reporterChat) {
            let textarea = this.$refs.messageInput;
            let action;
            if (this.selectedDialog.type === 'reporterChat' || reporterChat) {
                action = "sendmessage_external_reporter";
                this.typing = true;
            }
            if (this.selectedDialog.type === 'ownerChat') {
                action = "sendmessage_external_owner";
            }
            if (this.selectedDialog.type === 'ownerSeperateChat' || this.ownerChat) {
                action = "sendmessage_servicedesk_to_owneruser";
            }
            if (this.selectedDialog.type === 'supplierChat' && !this.ownerChat) {
                action = "sendmessage_servicedesk_to_supplier";
            }
            if (this.selectedDialog.type === 'specialistChat') {
                action = "sendmessage_servicedesk_to_mechanic";
            }
            if (this.selectedDialog.type === 'servicedeskChat') {
                action = "sendmessage_servicedesk_to_servicedesk";
            }
            if (this.fileRemoved || this.quotationAdded) {
                action = "sendmessage_servicedesk_to_supplier";
            }
            let idIncident =  parseInt(this.incidentInfo.id_incident);
            let body = this.incidentInfo.serviced_by_bm_user_first_name + ': ' + this.newMessage;
            let to;
            let from = this.buildingmanager.id_building_manager;
            if (this.incidentInfo.id_building_manager === this.buildingmanager.id_building_manager) {
                to   =  this.incidentInfo.original_id_building_manager;
            }
            else {
                body = this.buildingmanager.building_manager_name + ': ' + this.newMessage;
                to = this.incidentInfo.id_building_manager
            }
            if (this.selectedDialog.type === 'servicedeskChat') {
                body = this.newMessage;
            }
            if (typeof $event === 'string') {
                body = $event;
            }
            if (!this.ownerUserId) {
                this.ownerUserId = this.ownerChatId;
            }
            this.sendToServer(action, body, idIncident, to, from, this.ownerUserId);
            let dateSent = new Date();
            let month = dateSent.getMonth() + 1;
            month = month.toString();
            let element = document.getElementById('messages');
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
            this.newMessage = '';
            this.$store.dispatch('updateOwnerChat', '');
            this.$store.dispatch('updateFileRemoved', false);
            this.$store.dispatch('updateAppointmentMessage', '');
            this.$store.dispatch('updateColleagueAssigned', '');
            this.$store.dispatch('updatReporterAdvice', '');
            if (textarea) {
                textarea.blur();
                setTimeout(function () {
                    textarea.focus();
                }.bind(this), 200);
            }            
        },
        setAsUnread(dialog) {
            let idChat;
            for (var i = 0; i < dialog.messages.length; i++) {
                let message = dialog.messages[i];
                if (message.received) {
                    idChat = message.idChat;
                }
            }
            const data = new URLSearchParams();
            data.append('id_chat', idChat);
            let self = this;
            fetch('/api/v1/chat/setasunread', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.showWhatsAppChats();
            });
        },
        fillDefaultChat(message) {
            this.defaultMessageModalOpen = false;
            this.newMessage = message.message;
        }
    }
}
</script>
