import { update } from 'lodash';
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        count: 0,
        fileRemoved: false,
        fileAdded: false,
        quotationAdded: false,
        colleagueAssigned: '',
        invoiceAdded: false,
        chatRead: false,
        stickAction: '',
        incident: {},
        incidentsHistory: [],
        ownerLoaded: false,
        recommission: false,
        statsGet: false,
        overtimeItems: null,
        recurringJobsGet: false,
        loadAssets: false,
        resetSets: false,
        updateTypes: false,
        getImportantNotes: false,
        getNotess: false,
        mandateAdded: false,
        admin: false,
        repeatingModule: false,
        vve: false,
        twinq: false,
        serviceteam: 0,
        agreementflow: false,
        bmId: 0,
        serviceQr: '',
        assetsWithoutTypeCount: 0,
        mapsKey: 'AIzaSyBNE_rfIuZDZMAdjFBFXsqVU2DpyIMotVk',
        totalAssignments: 0,
        appointmentMessage: '',
        invoices: [],
        ownerChat: '',
        ownerChatId: 0,
        reloadChats: false,
        reporterAdvice: ''
    },
    getters: {
        fileRemoved: state => state.fileRemoved,
        fileAdded: state => state.fileAdded,
        quotationAdded: state => state.quotationAdded,
        colleagueAssigned: state => state.colleagueAssigned,
        invoiceAdded: state => state.invoiceAdded,
        chatRead: state => state.chatRead,
        stickAction: state => state.stickAction,
        incident: state => state.incident,
        incidentsHistory: state => state.incidentsHistory,
        ownerLoaded: state => state.ownerLoaded,
        recommission: state => state.recommission,
        statsGet: state => state.statsGet,
        overtimeItems: state => state.overtimeItems,
        recurringJobsGet: state => state.recurringJobsGet,
        loadAssets: state => state.loadAssets,
        resetSets:  state => state.resetSets,
        updateTypes: state => state.updateTypes,
        getImportantNotes: state => state.getImportantNotes,
        getNotess: state => state.getNotes,
        updateMandateAdded: state => state.mandateAdded,
        updateAdmin: state => state.admin,
        updateRepeatingModule: state => state.repeatingModule,
        updateVve: state => state.vve,
        updateTwinq: state => state.twinq,
        updateServiceteam: state => state.serviceteam,
        updateAgreementflow: state => state.agreementflow,
        updateBmId: state => state.bmId,
        updateServiceQr: state => state.serviceQr,
        updateAssetsWithoutTypeCount: state => state.assetsWithoutTypeCount,
        updateTotalAssignments: state => state.totalAssignments,
        updateAppointmentMessage: state => state.appointmentMessage,
        updateInvoices: state => state.invoices,
        updateOwnerChat: state => state.ownerChat,
        updateOwnerChatId: state => state.ownerChatId,
        updateReloadChats: state => state.reloadChats,
        updateReporterAdvice: state => state.reporterAdvice
    },
    mutations: {
        updateFileRemoved(state, payload) {
            Vue.set(state, 'fileRemoved', payload);
        },
        updateFileAdded(state, payload) {
            Vue.set(state, 'fileAdded', payload);
        },
        updateQuotationAdded(state, payload) {
            Vue.set(state, 'quotationAdded', payload);
        },
        updateColleagueAssigned(state, payload) {
            Vue.set(state, 'colleagueAssigned', payload);
        },
        updateInvoiceAdded(state, payload) {
            Vue.set(state, 'invoiceAdded', payload);
        },
        updateChatRead(state) {
            Vue.set(state, 'chatRead', true);
        },
        updateChatRead2(state) {
            Vue.set(state, 'chatRead', false);
        },
        updateStickAction(state, stickAction) {
            Vue.set(state, 'stickAction', stickAction);
        },
        updateIncident(state, payload) {
            Vue.set(state, 'incident', payload);
        },
        updateHistoryList(state, payload) {
            Vue.set(state, 'incidentsHistory', payload);
        },
        updateOwnerLoaded(state) {
            Vue.set(state, 'ownerLoaded', true);
        },
        updateRecommission(state) {
            Vue.set(state, 'recommission', true);
        },
        updateGetStats(state) {
            Vue.set(state, 'statsGet', true);
        },
        updateOvertime(state, payload) {
            Vue.set(state, 'overtimeItems', payload);
        },
        updateGetRecurringJobs(state) {
            Vue.set(state, 'recurringJobsGet', true);
        },
        updateLoadAssets(state, payload) {
            Vue.set(state, 'loadAssets', payload);
        },
        updateResetSets(state, payload) {
            Vue.set(state, 'resetSets', payload);
        },
        updateUpdateTypes(state) {
            Vue.set(state, 'updateTypes', true);
        },
        updateGetImportantNotes(state) {
            Vue.set(state, 'getImportantNotes', true);
        },
        updateGetNotes(state) {
            Vue.set(state, 'getNotess', true);
        },
        updateMandateAdded(state, payload) {
            Vue.set(state, 'mandateAdded', payload);
        },
        updateAdmin(state) {
            Vue.set(state, 'admin', true);
        },
        updateRepeatingModule(state) {
            Vue.set(state, 'repeatingModule', true);
        },
        updateVve(state) {
            Vue.set(state, 'vve', true);
        },
        updateTwinq(state, payload) {
            Vue.set(state, 'twinq', payload);
        },
        updateServiceteam(state, serviceteam) {
            Vue.set(state, 'serviceteam', serviceteam);
        },
        updateAgreementflow(state) {
            Vue.set(state, 'agreementflow', true);
        },
        updateBmId(state, payload) {
            Vue.set(state, 'bmId', payload);
        },
        updateServiceQr(state, payload) {
            Vue.set(state, 'serviceQr', payload);
        },
        updateAssetsWithoutTypeCount(state, payload) {
            Vue.set(state, 'assetsWithoutTypeCount', payload);
        },
        updateTotalAssignments(state, payload) {
            Vue.set(state, 'totalAssignments', payload);
        },
        updateAppointmentMessage(state, appointmentMessage) {
            Vue.set(state, 'appointmentMessage', appointmentMessage);
        },
        updateInvoices(state, invoices) {
            Vue.set(state, 'invoices', invoices);
        },
        updateOwnerChat(state, ownerChat) {
            Vue.set(state, 'ownerChat', ownerChat);
        },
        updateOwnerChatId(state, ownerChatId) {
            Vue.set(state, 'ownerChatId', ownerChatId);
        },
        updateReloadChats(state) {
            Vue.set(state, 'reloadChats', true);
        },
        updateReporterAdvice(state, reporterAdvice) {
            Vue.set(state, 'reporterAdvice', reporterAdvice);
        }
    },
    actions: {
        updateFileRemoved: ({commit}, payload) => commit('updateFileRemoved', payload),
        updateFileAdded: ({commit}, payload) => commit('updateFileAdded', payload),
        updateQuotationAdded: ({commit}, payload) => commit('updateQuotationAdded', payload),
        updateColleagueAssigned: ({commit}, payload) => commit('updateColleagueAssigned', payload),
        updateInvoiceAdded: ({commit}, payload) => commit('updateInvoiceAdded', payload),
        updateChatRead: ({commit}) => commit('updateChatRead'),
        updateChatRead2: ({commit}) => commit('updateChatRead2'),
        updateStickAction: ({commit}, stickAction) => commit('updateStickAction', stickAction),
        updateIncident: ({commit}, payload) => commit('updateIncident', payload),
        updateHistoryList: ({commit}, payload) => commit('updateHistoryList', payload),
        updateOwnerLoaded: ({commit}) => commit('updateOwnerLoaded'),
        updateRecommission: ({commit}) => commit('updateRecommission'),
        updateGetStats: ({commit}) => commit('updateGetStats'),
        updateOvertime: ({commit}, payload) => commit('updateOvertime', payload),
        updateGetRecurringJobs: ({commit}) => commit('updateGetRecurringJobs'),
        updateLoadAssets: ({commit}, payload) => commit('updateLoadAssets', payload),
        updateResetSets: ({commit}, payload) => commit('updateResetSets', payload),
        updateUpdateTypes: ({commit}) => commit('updateUpdateTypes'),
        updateGetImportantNotes: ({commit}) => commit('updateGetImportantNotes'),
        updateGetNotes: ({commit}) => commit('updateGetNotes'),
        updateMandateAdded: ({commit}, payload) => commit('updateMandateAdded', payload),
        updateAdmin: ({commit}) => commit('updateAdmin'),
        updateRepeatingModule: ({commit}) => commit('updateRepeatingModule'),
        updateVve: ({commit}) => commit('updateVve'),
        updateTwinq: ({commit}, payload) => commit('updateTwinq', payload),
        updateServiceteam: ({commit}, serviceteam) => commit('updateServiceteam', serviceteam),
        updateAgreementflow: ({commit}) => commit('updateAgreementflow'),
        updateBmId: ({commit}, payload) => commit('updateBmId', payload),
        updateServiceQr: ({commit}, payload) => commit('updateServiceQr', payload),
        updateAssetsWithoutTypeCount: ({commit}, payload) => commit('updateAssetsWithoutTypeCount', payload),
        updateTotalAssignments: ({commit}, payload) => commit('updateTotalAssignments', payload),
        updateAppointmentMessage: ({commit}, appointmentMessage) => commit('updateAppointmentMessage', appointmentMessage),
        updateInvoices: ({commit}, invoices) => commit('updateInvoices', invoices),
        updateOwnerChat: ({commit}, ownerChat) => commit('updateOwnerChat', ownerChat),
        updateOwnerChatId: ({commit}, ownerChatId) => commit('updateOwnerChatId', ownerChatId),
        updateReloadChats: ({commit}) => commit('updateReloadChats'),
        updateReporterAdvice: ({commit}, reporterAdvice) => commit('updateReporterAdvice', reporterAdvice)
    }
});